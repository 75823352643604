.footer {
    &__social-list {
        .list__item  {
            .list__icon-link {
                border: 1px solid $gray-dark;
                background-color: $color-primary;

                &:hover,
                &.focus-visible {
                    border-color: $color-accent-primary;
                    background-color: $color-accent-primary;

                    &:before {
                        display: none;
                    }

                    .footer__social-icon {
                        fill: $gray-lighter;
                    }
                }

                .footer__social-icon {
                    fill: $gray;
                }
            }
        }
    }

    &__social-handler {
        &:before {
            background: $gray-dark;
        }
    }

    .dropdown-list {
        &--with-breakpoint {
            .dropdown-list {
                &__label {
                    &:hover,
                    &.focus-visible {
                        color: $gray-lighter;
                    }
                }
            }
        }
    }
}
