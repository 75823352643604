.logo {
    &__image {
        @include mq($screen-xl) {
            height: 72px;
        }
    }

    @include mq($screen-l) {
        justify-content: center;
    }
}
