.article-item {
    &__banner,
    &__body {
        .image,
        img,
        picture {
            width: auto;
        }
    }

    &__media {
        .article-item__banner {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
