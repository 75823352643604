#contentarea {
    @include visually-hidden();
}

.page-wrapper {
    flex: $page-wrapper-flex;
    margin-bottom: $page-wrapper-margin-bottom;

    @include mq($screen-m) {
        margin-bottom: 64px;
    }
}
