.button {
    &--add-to {
        &:before {
            background-color: $color-accent-primary;
        }

        &:hover,
        &.focus-visible {
            &:before {
                background-color: $color-accent-primary;
            }
        }
    }
}
