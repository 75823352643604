// Colors
$red                         : #e71321;
$green                       : #0e8a00;

// Grayscale colors
$gray-darkest                : #212121;

// Semantic color scheme
$color-accent-primary        : $red;
$color-accent-secondary      : #b3090b;

// Typography
$font-family-secondary       : 'Roboto Condensed', sans-serif;

// Components
$form-elements-border-focus  : 1px solid $gray-darkest;
