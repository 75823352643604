$header__search-wrapper-max-width\@large      : 400px;
$header__search-wrapper-max-width\@extra-large: 664px;

$header__switcher-background-color            : transparent;

$header__switcher-label-background-color      : $color-primary;
$header__switcher-label-padding               : $spacer--small $spacer--semi-large $spacer--small $spacer--small;
$header__switcher-label-color                 : $white;

$header__switcher-content-bottom              : 100%;
$header__switcher-content-width               : 100%;
$header__switcher-content-padding             : $spacer--small $spacer--small 0;

$header__switcher-button-font-weight          : $font-weight-normal;
$header__switcher-button-min-height           : 0;
$header__switcher-button-text-decoration      : none;
$header__switcher-button-hover-text-decoration: underline;

$header__switcher-dropdown-z-index            : $header__search-wrapper-z-index + 1;
