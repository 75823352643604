.mega-menu {
    &__item {
        &--parent {
            .mega-menu__link {
                &:after {
                    content: '';
                    position: relative;
                    right: -4px;
                    width: 24px;
                    height: 24px;
                    background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path fill="#EAEAEA" fill-rule="nonzero" d="M15.884 9.294L12 13.17 8.116 9.294a.999.999 0 00-1.411 1.411l4.588 4.588a1 1 0 001.414 0l4.588-4.588a.996.996 0 10-1.41-1.41z"/></svg>');
                    background-repeat: no-repeat;
                    background-size: 24px;

                    @include mq($screen-xl) {
                        right: -12px;
                    }
                }
            }
        }
    }
}
