.contact-bar {
    display: flex;

    @include mq($screen-l) {
        background: $contact-bar__background\@large;
    }

    &__container {
        flex-direction: row;

        @include mq($screen-l) {
            flex-direction: row-reverse;
        }
    }

    &__link {
        @include mq($screen-l) {
            color: $contact-bar__link-color\@large;
            background-color: $contact-bar__link-bg\@large;

            &:hover {
                color: $contact-bar__link-color--hover\@large;
                background: $contact-bar__link-hover-background-hover\@large;

                .contact-bar__text {
                    color: $contact-bar__link-color--hover\@large;
                }

                .contact-bar__icon {
                    .icon {
                        fill: $contact-bar__icon-fill-hover\@large;
                    }
                }
            }
        }
    }

    &__icon {
        @include mq($screen-l) {
            .icon {
                fill: $contact-bar__icon-fill\@large;
            }
        }
    }

    &__list {
        &--icons {
            .contact-bar__link {
                font-family: $font-family-secondary;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__wrapper {
        display: flex;
    }

    &:not(.contact-bar--vertical) {
        background: $color-primary;

        .contact-bar__list {
            display: none;

            @include mq($screen-l) {
                display: flex;
            }
        }
    }

    &--vertical {
        .google-translator {
            display: none;
        }
    }
}
