$google-translator__icon-size: 20px;

body {
    top: 0 !important; // sass-lint:disable-line no-important
}

.goog-te-banner-frame.skiptranslate {
    display: none;
}

#gtx-trans {
    display: none;
}

.google-translator {
    display: flex;
    align-items: center;
    padding: 0 $spacer--extra-large 0 0;
    margin: 0 0 0 $spacer--medium;

    @include mq($screen-l) {
        margin: 0;
    }

    .goog-te-gadget-simple {
        display: flex;
        background: none;
        border: none;

        &:before,
        &:after {
            content: '';
            position: relative;
            left: -8px;
            width: $google-translator__icon-size;
            height: $google-translator__icon-size;
            background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><g fill="none" fill-rule="evenodd"><path d="M0 0h20v20H0z"/><g fill-rule="nonzero"><path fill="#4285F4" d="M19.579 10.234c0-.677-.055-1.359-.172-2.025h-9.403v3.839h5.384a4.612 4.612 0 01-1.992 3.029v2.49h3.212c1.887-1.735 2.97-4.3 2.97-7.333z"/><path fill="#34A853" d="M10.004 19.974c2.688 0 4.956-.882 6.608-2.406l-3.213-2.491c-.894.608-2.047.952-3.392.952-2.6 0-4.806-1.754-5.597-4.113H1.095v2.568a9.972 9.972 0 008.909 5.49z"/><path fill="#FBBC04" d="M4.407 11.916a5.966 5.966 0 010-3.817V5.53H1.095a9.978 9.978 0 000 8.953l3.312-2.568z"/><path fill="#EA4335" d="M10.004 3.982a5.42 5.42 0 013.824 1.494l2.846-2.846a9.578 9.578 0 00-6.67-2.593 9.967 9.967 0 00-8.909 5.494L4.407 8.1c.787-2.363 2.996-4.117 5.597-4.117z"/></g></g></svg>');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: $google-translator__icon-size;
        }

        &:after {
            left: auto;
            right: -4px;
            background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><g fill="none" fill-rule="evenodd"><path d="M24 24H0V0h24z" opacity=".87"/><path fill="#EAEAEA" fill-rule="nonzero" d="M15.884 9.294L12 13.17 8.116 9.294a.999.999 0 00-1.411 1.411l4.588 4.588a1 1 0 001.414 0l4.588-4.588a.996.996 0 10-1.41-1.41z"/></g></svg>');
            background-size: 24px;
        }

        & > img {
            display: none;
        }

        .goog-te-menu-value {
            color: $gray-lighter;
            font-size: $font-size-small;
            text-transform: uppercase;

            & > span {
                &:not(:first-child) {
                    display: none;
                }
            }

            & > img {
                display: none;
            }
        }
    }
}

.goog-te-menu-frame {
    box-shadow: none !important; // sass-lint:disable-line no-important
}
