.search-form {
    &:focus-within {
        .search-form__button {
            border-color: $color-primary;
            transition: none;
        }
    }

    &__button {
        background: $white;
        transition: none;
    }
}
