.shipping-address {
    &__form-login &__email .input__label > span {
        font-weight: $font-weight-bold;
    }
}
#label_carrier_null_calcurates,
.no-display {
    display: none;
}

.table-checkout-shipping-method tbody td.col-error:first-child {
    width: 100%;
}
