.braintree {
    &__input--date {
        display: flex;
        flex-wrap: wrap;

        .input__field {
            flex: 1;
        }
    }

    &__tooltip-wrapper {
        position: relative;

        .braintree__tooltip {
            height: 48px;
            right: $spacer--medium;
        }
    }

    &__error {
        display: none;
        width: 100%;
    }

    img {
        width: auto;
    }

    .ccard {
        & > .field.cvv {
            & > .control {
                &.braintree__tooltip-wrapper {
                    padding-right: 0;
                }
            }
        }
    }
}

.braintree-hosted-fields-invalid {
    & ~ .braintree__error {
        display: flex;
    }
}

.braintree-paypal-fieldset {
    padding: 0;
    border: none;
}
