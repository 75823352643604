.newsletter {
    &__button {
        &:before {
            background-color: $color-accent-primary;
        }
    }

    &__heading {
        @include mq($screen-m) {
            max-width: 250px;
        }

        @include mq($screen-xl) {
            margin: 0 80px 0 0;
        }
    }

    &__controls {
        @include mq($screen-xl) {
            max-width: 100%;
        }
    }

    .checkbox__icon--checked {
        fill: $color-primary;
    }

    .checkbox__label {
        &:after {
            background-color: $gray-lighter;
        }
    }
}
