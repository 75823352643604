.product-grid-item {
    &__stock-status {
        display: none;
    }

    &__price,
    &__actions {
        width: auto;
    }

    &__rating {
        margin: $spacer 0;
    }

    &--slider-item {
        .product-grid-item__image-wrapper {
            .image {
                width: auto;
            }
        }

        .product-grid-item__details {
            flex: 1 0 auto;
        }

        .product-grid-item__link {
            flex: 0 0 auto;
        }
    }
}
