.product-view {
    &__add-to-cart {
        .button__text {
            display: block;
        }
    }

    .brief-info {
        display: none;

        @include mq($screen-xl) {
            visibility: hidden;
        }
    }

    .fotorama__nav.fotorama__nav--thumbs,
    .fotorama__nav-wrap--vertical.fotorama__nav-wrap {
        /** To avoid override by inline styles */
        display: block !important;
    }
}
