$headings-margin                          : 0 0 0.5em 0 !default;
$headings-margin--page                    : $spacer--medium 0 !default;

$headings-font-family                     : $font-family-secondary !default;
$headings-font-family--secondary          : $font-family-base !default;
$headings-font-weight                     : $font-weight-medium !default;
$headings-line-height                     : $font-line-height !default;
$headings-color                           : $color-primary !default;

$headings-text-transform                  : uppercase !default;
$headings-text-transform--page            : uppercase !default;

$heading-font-size--page\@screen-m        : $font-size-super-extra-large !default;
$heading-font-size--page                  : $font-size-extra-large !default;
$heading-font-size--first-level\@screen-m : $font-size-super-extra-large !default;
$heading-font-size--first-level           : $font-size-extra-large !default;
$heading-font-size--second-level\@screen-m: $font-size-extra-large !default;
$heading-font-size--second-level          : $font-size-large !default;
$heading-font-size--third-level\@screen-m : $font-size-large !default;
$heading-font-size--third-level           : $font-size-medium !default;
$heading-font-size--fourth-level          : $font-size-medium !default;
$heading-font-size--fifth-level           : $font-size-medium !default;
$heading-font-size--sixth-level           : $font-size-medium !default;

.heading {
    display: block;
    margin: $headings-margin;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    text-transform: $headings-text-transform;

    &--first-level {
        font-size: $heading-font-size--first-level;
        @include mq($screen-m) {
            font-size: $heading-font-size--first-level\@screen-m;
        }
    }

    &--second-level {
        font-size: $heading-font-size--second-level;
        @include mq($screen-m) {
            font-size: $heading-font-size--second-level\@screen-m;
        }
    }

    &--third-level {
        font-size: $heading-font-size--third-level;
        @include mq($screen-m) {
            font-size: $heading-font-size--third-level\@screen-m;
        }
    }

    &--fourth-level {
        font-size: $heading-font-size--fourth-level;
    }

    &--fifth-level {
        font-size: $heading-font-size--fifth-level;
    }

    &--sixth-level {
        font-size: $heading-font-size--sixth-level;
    }

    &--font-secondary {
        font-family: $headings-font-family--secondary;
    }

    &--page {
        margin: $headings-margin--page;
        font-size: $heading-font-size--page;
        text-transform: $headings-text-transform--page;
        @include mq($screen-m) {
            font-size: $heading-font-size--page\@screen-m;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .heading;
}

h1 {
    @extend .heading--first-level;
}

h2 {
    @extend .heading--second-level;
}

h3 {
    @extend .heading--third-level;
}

h4 {
    @extend .heading--fourth-level;
}

h5 {
    @extend .heading--fifth-level;
}

h6 {
    @extend .heading--sixth-level;
}
