.fotorama {
    width: 100%;

    .fotorama__nav-wrap {
        display: flex;

        @include mq($screen-m) {
            display: block;
        }

        &--vertical,
        &--horizontal {
            .fotorama__nav__frame--thumb {
                @extend .gallery__thumb;
            }

            .fotorama__active {
                box-sizing: border-box;
                &.fotorama__nav__frame--thumb {
                    @extend .gallery__thumb--active;
                    border-width: 2px;
                }
                &.fotorama__nav__frame--dot {
                    @extend .gallery__thumb-dot--active;
                }

            }

            .fotorama__arr--disabled {
                display: none !important; // sass-lint:disable-line no-important
            }

            .fotorama__thumb__arr {
                @extend .gallery__thumb-arr;

                background-color: $white;

                &--left {
                    .fotorama__thumb {
                        &--icon {
                            background-image: url('../images/fotorama/angle-up.svg');
                        }
                    }
                }

                &--right {
                    .fotorama__thumb {
                        &--icon {
                            background-image: url('../images/fotorama/angle-down.svg');
                        }
                    }
                }
            }
        }

        &--horizontal {
            .fotorama__thumb__arr {
                &--left,
                &--right {
                    .fotorama__thumb--icon {
                        @extend .gallery__icon-arrow;

                        position: relative;
                        height: 24px;
                        width: 24px;
                        padding: 0;
                        margin: auto;
                        background-position: center;
                        fill: $gallery__icon-arrow-color;
                    }
                }
            }
        }

        &--vertical {
            .fotorama__nav__frame--thumb {
                @extend .gallery__thumb--vertical;
            }

            .fotorama__thumb__arr {
                &--left,
                &--right {
                    .fotorama__thumb--icon {
                        @extend .gallery__icon-arrow;
                        @extend .gallery__icon-arrow--vertical;

                        position: relative;
                        height: 24px;
                        width: 24px;
                        padding: 0;
                        margin: auto;
                        background-position: center;
                        fill: $gallery__icon-arrow-color;
                    }
                }
            }
        }
    }

    &__dot {
        @extend .gallery__dot;

        .fotorama__active & {
            @extend .gallery__dot--active;
        }
    }

    .fotorama__stage {
        padding-bottom: $spacer--medium;

        @include mq($screen-m) {
            padding-bottom: 0;
            margin-bottom: $spacer--medium;
        }

        @include mq($screen-l) {
            margin-left: $spacer--medium;
            margin-bottom: 0;
        }

        @include mq($screen-xl) {
            margin-left: $spacer--extra-large;
        }
    }

    .fotorama__stage,
    .fotorama__nav {
        &.fotorama__shadows {
            &--top,
            &--bottom,
            &--right,
            &--left {
                &:before {
                    background-image: none;
                }

                &:after {
                    background-image: none;
                }
            }
        }
    }

    .fotorama-video-container {
        cursor: pointer;

        &.video-unplayed {
            &:after {
                content: '';
                position: absolute;
                top: 12px;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100px;
                height: 100px;
                margin: auto;
                opacity: 0.8;
                background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom right;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }
        }

        &.fotorama__product-video--loaded {
            .product-video {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .product-video {
            display: none;
        }

        .fotorama__img {
            pointer-events: none;
        }
    }

    .fotorama__thumb-border {
        display: none;
    }

    &__wrapper {
        width: 100%;

        &:not(._block-content-loading) {
            padding: 0 !important; // sass-lint:disable-line no-important
        }

        &._block-content-loading {
            .fotorama__nav--dots,
            .fotorama__nav--thumbs {
                display: none;
            }

            .loader {
                background-color: transparent;
            }

            @include mq($screen-l) {
                margin-left: 66px;
                & > *:not(.loader) {
                    opacity: 0;
                }
            }

            @include mq($screen-xl) {
                margin-left: 98px;
            }
        }
    }
}
