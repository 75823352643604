$paypal-review__product-image-width: 80px !default;

.paypal-review {
    &__product-image {
        width: $paypal-review__product-image-width;
    }

    tfoot {
        tr {
            th {
                border-top: $border-width-base $border-style-base $gray-light;
                border-bottom: none;
            }
        }
    }
}
