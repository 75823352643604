@import 'minicart-product-variables';

.minicart-product {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    padding: $minicart-product__padding;
    border-bottom: $minicart-product__border;

    &:last-child {
        border-bottom: none;
    }

    &__image {
        flex-basis: $minicart-product__image-width;

        img {
            width: $minicart-product__image-width;
            max-width: $minicart-product__image-width;
            height: auto;
        }
    }

    &__data {
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        padding: $minicart-product__data-padding;
    }

    &__info {
        margin: $minicart-product__info-margin;

        @include mq($screen-m) {
            flex-grow: 1;
        }
    }

    &__name {
        margin-bottom: $minicart-product__spacer;
        font-size: $minicart-product__name-font-size;
        line-height: $minicart-product__name-line-height;
        text-transform: $minicart-product__name-text-transform;
    }

    &__link {
        text-decoration: $minicart-product__link-text-decoration;
        font-weight: $minicart-product__link-font-weight;
        color: $minicart-product__link-color;
    }

    &__attributes {
        margin-bottom: $minicart-product__spacer;

        display: none; //hidden due to lack of design TO DO
    }

    &__attributes-button {
        display: flex;
        align-items: center;
        margin: $minicart-product__attributes-button-margin;
        padding: $minicart-product__attributes-button-padding;
        font-size: $minicart-product__attributes-button-font-size;

        .dropdown-list__icon {
            position: static;
            margin: $minicart-product__attributes-list-icon-margin;
            fill: $minicart-product__attributes-button-color;
        }

        &.dropdown-list__label {
            color: $minicart-product__attributes-button-color;
            background-color: $minicart-product__attributes-button-bg-color;

            &.focus-visible,
            &:hover {
                background-color: $minicart-product__attributes-button-bg-color;
            }
        }
    }

    &__attributes-list {
        padding: $minicart-product__attributes-list-padding;

        .list__label,
        .list__value {
            color: $minicart-product__attributes-color;
            font-size: $minicart-product__attributes-font-size;
            line-height: $minicart-product__attributes-line-height;
        }

        .list__label {
            padding: $minicart-product__attributes-label-padding;
            font-weight: $minicart-product__attributes-label-font-weight;
        }

        .list__value {
            padding: $minicart-product__attributes-value-padding;
        }
    }

    &__price {
        display: flex;
        align-items: $minicart-product__price-align;
        font-size: $minicart-product__price-font-size;
        font-weight: $minicart-product__price-font-weight;

        @include mq($screen-m) {
            justify-content: flex-end;
        }

        .price__value {
            display: block;
            font-size: $minicart-product__price-font-size;

            @include mq($screen-m) {
                text-align: right;
            }

            &--old {
                font-size: $minicart-product__price-font-size--old;

                @include mq($screen-m) {
                    margin: 0;
                }
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mq($screen-m) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__actions {
        display: flex;
    }

    &__field {
        max-width: $minicart-product__field-max-width;
    }

    &__qty {
        display: flex;
        margin: $minicart-product__qty-margin;

        @include mq($screen-m) {
            margin: $minicart-product__qty-margin\@medium;
        }
    }

    &__name-wrapper {
        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__update {
        padding: $minicart-product__update-btn-padding;

        @include mq($screen-m) {
            padding: $minicart-product__update-btn-padding\@medium;
        }
     }
}
