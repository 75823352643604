.cms-page {
    &__content {
        .cms-contact &,
        .cms-contact-us & {
            @include mq($screen-m) {
                max-width: $cms-page__max-width\@medium;
            }

            @include mq($screen-l) {
                max-width: $cms-page__max-width\@large;
            }

            @include mq($screen-xl) {
                max-width: $cms-page__max-width\@xlarge;
            }
        }

        .col3-set {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        img {
            width: auto;
            height: auto;
        }

        a {
            color: $color-accent-primary;
        }

        p {
            margin: 0 0 $spacer--small;
        }
    }
}
