.header {
    &__dropdown-switcher {
        width: auto;
        background-color: $header__switcher-background-color;

        .dropdown-list__label  {
            background-color: $header__switcher-label-background-color;
            padding: $header__switcher-label-padding;
            color: $header__switcher-label-color;
            white-space: nowrap;
            text-decoration: none;

            &:hover,
            &.focus-visible {
                text-decoration: $header__switcher-button-hover-text-decoration;
            }

            &[aria-expanded=true] {
                background-color: $header__switcher-label-background-color;
                color: $header__switcher-label-color;

                .dropdown-list__icon {
                    fill: $header__switcher-label-color;
                }
            }

            &:hover {
                .dropdown-list__icon {
                    fill: $header__switcher-label-color;
                }
            }
        }

        .dropdown-list__content  {
            position: absolute;
            top: $header__switcher-content-bottom;
            width: $header__switcher-content-width;
            padding: $header__switcher-content-padding;
            background-color: $header__switcher-label-background-color;
            z-index: $header__switcher-dropdown-z-index;

            .button {
                font-weight: $header__switcher-button-font-weight;
                min-height: $header__switcher-button-min-height;
                text-decoration: $header__switcher-button-text-decoration;

                &:hover,
                &:focus,
                &.focus-visible {
                    text-decoration: $header__switcher-button-hover-text-decoration;
                }
            }

            .switcher-option {
                .link {
                    color: $header__switcher-label-color;
                }
            }
        }

        .dropdown-list__icon {
            fill: $header__switcher-label-color;
        }
    }
}
