$footer__background-color           : $color-primary;
$footer__border                     : none;

$footer__link-color                 : $gray-light;
$footer__link-color-hover           : $gray-light;

$footer__links-dropdown-background  : $color-primary;
$footer__links-label-background     : $color-primary;
$footer__links-label-color          : $gray-lighter;

$footer__social-list-title-color    : $gray-lighter;

$footer__bottom-bar-background      : #131313;

$footer__scroll-top-top             : -48px;
$footer__scroll-top-icon-fill       : $gray-lighter;
$footer__scroll-top-bg              : $color-accent-primary;
$footer__scroll-top-bg-hover        : $color-accent-secondary;

$footer__newsletter-border          : $spacer--extra-small solid $gray-dark;
