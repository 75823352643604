$contact-bar__background\@large                     : $color-primary;

$contact-bar__link-hover-text-decoration            : underline;
$contact-bar__link-hover-background-hover           : transparent;

$contact-bar__link-bg\@large                        : $color-primary;
$contact-bar__link-color\@large                     : $gray-lighter;
$contact-bar__link-color--hover\@large              : $gray-lighter;
$contact-bar__link-hover-background-hover\@large    : $color-primary;

$contact-bar__icon-fill\@large                      : $gray-lighter;
$contact-bar__icon-fill-hover\@large                : $gray-lighter;
