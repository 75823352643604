.brief-info {
    display: block;

    &--dividers {
        .brief-info__item {
            padding: $spacer $spacer--semi-medium;
            justify-content: center;

            @include mq($screen-m) {
                padding: 0 $spacer--semi-medium;
                justify-content: flex-start;
            }

            &:not(:last-child) {
                &:after {
                    display: none;

                    @include mq($screen-m) {
                        display: block;
                    }
                }
            }
        }

        .brief-info__icon-wrapper {
            margin: 0 $spacer $spacer 0;

            @include mq($screen-m) {
                margin: 0 0 $spacer;
            }
        }
    }
}
